import React from 'react'
import Logo from '../../assets/Logo1BG.png'
import YoutubeTip from './YoutubeTip'
import InstagramTip from './InstagramTip'
import TelegramTip from './TelegramTip'
import { Link } from 'react-router-dom'

const Footer = () => {
    const links = [
        {
            title: 'Сервис',
            content: ['Для кого', 'Возможности', 'Тарифы'],
            links: ['#cost', '#', , '#']
        },
        {
            title: 'Клиентам',
            content: ['Вход', 'Регистрация', 'Восстановления пароля'],
            links: ['https://app.bb-data.pro/login', 'https://app.bb-data.pro/signup', '#']
        },
        {
            title: 'Документы',
            content: ['Пользовательское соглашение', 'Политика конфиденциальности', 'Публичная оферта', ' Использование cookie', 'Техническая поддержка'],
            links: ['/docs/offerta', '/docs/offerta', '/docs/offerta', '/docs/offerta', '/docs/offerta']
        }
    ]
    return (
        <div className='xl:flex xl:bg-[#eaf3fd] xl:mt-20 justify-center'>
            <footer className='bg-[#eaf3fd] xl:px-10 ss:px-10 mt-12 xl:w-full md:mt-24 px-4 md:px-20 flex max-w-[1920px] flex-col gap-10 xl:pt-0 pt-14 pb-10'>
                <div className='flex flex-col gap-14 xl:flex-row justify-between'>
                    <div className='flex flex-col gap-7'>
                        <div className='flex flex-col gap-4'>
                            <img src={Logo} alt='logo' className='max-w-[200px] ss:max-w-[300px] md:max-w-[400px]' />
                            <p className='max-w-[300px] md:text-lg text-gray-500'>
                                Аналитика и оцифровка продаж <span className='text-purple-600'>Wildberries</span> и <span className='text-blue-600'>Ozon</span>
                            </p>
                        </div>
                        <div className='flex gap-6'>
                            <a href='#' target='_blank'>
                                <YoutubeTip />
                            </a>
                            <a href='#' target='_blank'>
                                <InstagramTip />
                            </a>
                            <a href='#'>
                                <TelegramTip />
                            </a>
                        </div>
                    </div>
                    <div className='flex flex-wrap gap-7  md:flex-row xl:gap-40  ss:flex-wrap justify-between'>
                        {links.map((item, index1) => (
                            <div key={index1} className='flex mr-20 ss:mr-0 flex-col gap-2 md:gap-4'>
                                <h3 className='text-xl md:text-2xl font-bold text-black'>{item.title}</h3>
                                <div className='flex flex-col gap-1 md:gap-3'>
                                    {item.content.map((item, index) =>
                                        index1 !== 2 ? (
                                            <a
                                                href={
                                                    index1 === 0 && index === 2
                                                        ? '#cost'
                                                        : index1 === 1 && index === 0
                                                        ? 'https://app.bb-data.pro/login'
                                                        : index1 === 1 && index === 1
                                                        ? 'https://app.bb-data.pro/signup'
                                                        : ''
                                                }
                                                target={index1 !== 0 ? '_blank' : ''}
                                                key={index}
                                                className="relative w-fit active:scale-95 duration-300 transition-all inline-block text-gray-500 text-base md:text-2xl after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-[1px] after:w-0 after:bg-gray-500 after:transition-all after:duration-500 hover:after:w-full"
                                            >
                                                {item}
                                            </a>
                                        ) : (
                                            <Link
                                                to={index === 0 ? '/docs/agreement' : index === 1 ? '/docs/policy' : index === 2 ? '/docs/offerta' : index === 3 ? '/docs/cookie' : '/docs/help'}
                                                className="relative w-fit active:scale-95 duration-300 transition-all inline-block text-gray-500 text-base md:text-2xl after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-[1px] after:w-0 after:bg-gray-500 after:transition-all after:duration-500 hover:after:w-full"
                                            >
                                                {item}
                                            </Link>
                                        )
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='flex justify-between'>
                    <div className='flex flex-col gap-2'>
                        <p className='text-gray-500 text-lg md:text-xl'>© 2022–2024 BB-DATA</p>
                        <span className='text-base md:text-xl text-gray-500 max-w-[350px]'>Информация, размещённая на сайте, не является публичной офертой </span>
                    </div>
                    <div></div>
                </div>
            </footer>
        </div>
    )
}

export default Footer
