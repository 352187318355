import React from 'react'

const TryitSection = ({ Image, className, image }) => {
    return (
        <section className='flex bg-grad flex-col md:flex-row md:pb-14 ss:px-10 ss:pt-10 relative rounded-2xl xl:p-10 px-5 pt-5 justify-between mt-20 xl:mt-40 items-center'>
            <div className='flex flex-col  gap-5 md:gap-10'>
                <h2 className='text-white text-2xl md:text-[60px] ss:text-4xl font-semibold'>
                    Начните экономить <br className='hidden ss:flex sm:hidden' /> своё <br className='hidden sm:flex md:hidden' /> время
                </h2>
                <div className='flex flex-col gap-5'>
                    <p className='text-white text-base ss:text-xl font-medium'>
                        5-дневный пробный период с неограниченным
                        <br className='hidden md:flex' /> доступом ко всем возможностям
                    </p>
                    <a href='https://app.bb-data.pro/signup' target='_blank'>
                        <button className='w-full  bg-white max-w-[350px] text-black rounded-3xl p-[10px] text-lg font-semibold active:scale-95 duration-300 transition-all hover:opacity-90'>
                            Начать бесплатно
                        </button>
                    </a>
                </div>
            </div>
            <img src={Image} alt='icon' className={className} />
            <img src={image} alt='' className='absolute blur-[2px] right-10 sm:right-28 bottom-24 xl:top-20 rotate-45 max-w-[70px] xl:max-w-[140px]' />
        </section>
    )
}

export default TryitSection
