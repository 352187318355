import { Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import Main from './pages/Main'
import Offerta from './pages/Offerta'
import Header from './components/header/Header'
import Agreement from './pages/Agreement'
import Help from './pages/Help'
import Policy from './pages/Policy'
import Cookies from './pages/Cookies'
import Footer from './components/footer/Footer'

const App = () => {
    return (
        <BrowserRouter>
            <div className='flex justify-center'>
                <Header />
            </div>
            <Routes>
                <Route path='/' element={<Main />} />
                <Route path='/docs/agreement' element={<Agreement />} />
                <Route path='/docs/offerta' element={<Offerta />} />
                <Route path='/docs/help' element={<Help />} />
                <Route path='/docs/policy' element={<Policy />} />
                <Route path='/docs/cookie' element={<Cookies />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}

export default App
