import React from 'react'

const DocsComponent = () => {
    return (
        <div className='flex items-center gap-1'>
            <a href='/' className='text-blue-400'>
                Главная
            </a>
            <span className='text-gray-500 text-xs'> ></span> Документы
        </div>
    )
}

export default DocsComponent
