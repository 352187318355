import React, { useState, useRef, useEffect } from 'react'

const ReviewCard = ({ author, text }) => {
    const contentRef = useRef(null)
    const [isExpanded, setIsExpanded] = useState(false)
    const [isOverflowing, setIsOverflowing] = useState(false)

    useEffect(() => {
        if (contentRef.current.scrollHeight > 300) {
            setIsOverflowing(true) // Проверяем, если текст больше 300px
        }
    }, [])

    const toggleExpand = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <div className={`review-card ${isExpanded ? 'expanded' : 'collapsed'}`}>
            <div ref={contentRef} className={`review-text ${isExpanded ? 'expanded' : 'collapsed'}`}>
                {text}
            </div>
            {isOverflowing && (
                <button className='toggle-btn' onClick={toggleExpand}>
                    {isExpanded ? 'Скрыть' : 'Читать полностью'}
                    <span className={`arrow ${isExpanded ? 'up' : 'down'}`}></span>
                </button>
            )}
            <p className='review-author'>— {author}</p>
        </div>
    )
}

const Reviews = () => {
    const reviews = [
        {
            author: 'Сергей',
            text: 'Долго искал сервис аналитики, который подходит под мои требования: недорогой, точный, функциональный. Методом перебора пришёл к Mpassistant – он заменяет собой полноценного сотрудника, а по цене как доставка пиццы. До этого вёл учёт только в своих табличках и к ним возвращаться теперь нет никакого желания, ведь тут всё быстрее, точнее и проще...'
        },
        {
            author: 'Андрей',
            text: ' Изначально нашёл сервис в поиске Яндекса, когда искал калькулятор unit-экономики товара. Калькулятор полностью устроил и решил пользоваться самим сайтом. К тому же это было для меня бесплатно, так как проходил по условиям бесплатного доступа: оборот до 200т.р в месяц. Этого не встретить на других сайтах, это возможность развиваться на ранних этапах, без вложения огромных сумм для доступа к подобным сайтам. Радует поддержка, получал ответы на все вопросы, выслушивали все мои рекомендации по улучшению сервиса. Какие‑то из них уже внедрены! Сервис ориентирован на аналитику своего магазина, продаж. Удобно анализировать финансовую составляющую. Бывают акции, когда можно хорошо сэкономить на подписке. Желаю сервису развития и успехов!'
        },
        {
            author: 'Мария',
            text: 'Было желание найти точки роста для своих магазинов и не очень хотелось разбираться в нюансах настройки аналитики. Здесь я подключилась за несколько минут теперь у меня на руках всегда точная информация...'
        }
    ]

    return (
        <div className='reviews'>
            <h2>Отзывы</h2>
            <div className='review-cards-container'>
                {reviews.map((review, index) => (
                    <ReviewCard key={index} author={review.author} text={review.text} />
                ))}
            </div>
        </div>
    )
}

export default Reviews
