import React, { useState } from 'react'
import adv from '../../assets/adverts.png'
import anal from '../../assets/anal.png'
import sales from '../../assets/sales.png'
import stats from '../../assets/stats.png'

const TabsSection = () => {
    const [activeTab, setActiveTab] = useState(0)

    const tabs = [
        {
            title: 'Финансовая аналитика',
            description: 'Инструменты для финансовой аналитики',
            content: ['Отчёт о прибылях и убытках (P&L)', 'Отчёт о движении денежных средств (ДДС)', 'Журнал операций', 'Сводка продаж', 'Учёт самовыкупов'],
            img: anal
        },
        {
            title: 'Товарная аналитика',
            description: 'Инструменты для товарной аналитики',
            content: [
                'Сводка продаж по артикулам и размерам',
                'ABC-анализ ассортимента',
                'Управление себестоимость, ценами, скидками',
                'Контроль остатков',
                'Работа с поставками Wildberries',
                'Расчет поставок'
            ],
            img: sales
        },
        {
            title: 'Аналитика внутренней рекламы',
            description: 'Инструменты для аналитики внутренней рекламы на маркетплейсах',
            content: [
                'Аналитика рекламы',
                'Учет расходов по рекламным кампаниям',
                'Список рекламных кампаний с детальной информацией в одном месте',
                'Определение реальной рекламной ставки в поиске Wildberries '
            ],
            img: adv
        },
        {
            title: 'Статистика',
            description: 'Инструменты для статистики',
            content: ['Дашборд', 'Список всех заказов', 'География заказов и продаж', 'Сравнение статистики по периодам', 'Сводная информация о заказах по времени'],
            img: stats
        },
        { title: 'Инструменты', description: 'Дополнительные инструменты', content: ['Калькулятор unit экономики', 'Калькулятор участия в акциях Wildberries'] }
    ]

    return (
        <>
            <div className='xl:tabs mx-4 mt-5 md:mt-20 hidden md:flex flex-col' id='about'>
                <h2 className='font-bold text-2xl md:text-[60px] md:text-5xl text-start '>Возможности сервиса</h2>
                <div className='flex justify-center items-center xl:mt-20 mt-10'>
                    <div className='flex flex-col bg-blocks rounded-xl justify-center items-center w-full'>
                        <div className='tab-list rounded-xl flex flex-wrap justify-center'>
                            {tabs.map((tab, index) => (
                                <li
                                    key={index}
                                    className={`tab ${
                                        activeTab === index ? 'activePhone xl:active' : ''
                                    } p-4 text-base lg:text-xl  xl:text-2xl text-sky-600 duration-300 transition-all hover:text-black`}
                                    onClick={() => setActiveTab(index)}
                                >
                                    {tab.title}
                                </li>
                            ))}
                        </div>
                        <div className='tab-content flex flex-col md:flex-row items-center max-w-[1323px] w-full p-8 justify-between'>
                            {tabs.map((tab, index) => (
                                <div key={index} className={`content ${activeTab === index ? 'fade-in' : 'fade-out'} flex flex-col md:flex-row justify-between w-full`}>
                                    <div className='flex flex-col gap-5 md:w-1/2'>
                                        <h2 className='font-semibold text-[24px] md:text-[32px]'>{tab.description}</h2>
                                        <div className='pl-5'>
                                            {tab.content.map((item, index) => (
                                                <li key={index} className='text-sky-600 pt-1'>
                                                    <span className='text-gray-600 text-lg'>{item}</span>
                                                </li>
                                            ))}
                                        </div>
                                        <a href='https://app.bb-data.pro/signup' target='_blank'>
                                            <button className='bg-grad max-w-[412px] text-white rounded-full h-14 active:scale-95 transition-all duration-300 pr-10 pl-10 mx-auto'>
                                                Попробовать бесплатно
                                            </button>
                                        </a>
                                    </div>
                                    {tab.img && <img src={tab.img} alt={tab.title} className='max-w-[700px] md:object-contain md:w-1/2 mt-5 md:mt-0' />}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='md:hidden tabs mt-5'>
                <h2 className='font-bold text-2xl text-start ss:text-3xl'>Возможности сервиса</h2>
                <div className='flex justify-center items-center mt-10'>
                    <div className='flex flex-col bg-blocks rounded-xl justify-center items-center w-full'>
                        {tabs.map((tab, index) => (
                            <div key={index} className={`w-full ${index !== 0 ? 'border-t-[1px] border-sky-500' : ''}`}>
                                <div
                                    className={`p-5 text-lg sm:text-2xl text-sky-600 duration-300 transition-all hover:text-black cursor-pointer ${activeTab === index ? 'bg-gray-200' : ''}`}
                                    onClick={() => setActiveTab(activeTab === index ? null : index)} // Toggle active tab
                                >
                                    {tab.title}
                                </div>

                                <div className={`overflow-hidden transition-all duration-300 ease-in-out ${activeTab === index ? 'max-h-screen' : 'max-h-0'}`}>
                                    <div className='p-5 border-t ss:flex flex-col ss:gap-5 items-center border-gray-300'>
                                        <h2 className='font-semibold text-xl  ss:leading-8  ss:text-[32px]'>{tab.description}</h2>
                                        <div className='pl-5 ss:w-full'>
                                            {tab.content.map((item, idx) => (
                                                <li key={idx} className='text-sky-600 ss:text-xl pt-1 sm:pt-4 sm:text-2xl'>
                                                    <span className='text-gray-600 text-base sm:text-2xl'>{item}</span>
                                                </li>
                                            ))}
                                        </div>
                                        {tab.img && <img src={tab.img} alt={tab.title} className='max-w-[280px] sm:max-w-[650px] ss:max-w-[500px] mt-4' />}
                                        <a href='https://app.bb-data.pro/signup' target='_blank'>
                                            <button className='bg-grad max-w-[412px] text-white rounded-full h-14 active:scale-95 transition-all duration-300 pr-10 pl-10 mx-auto mt-4'>
                                                Попробовать бесплатно
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TabsSection
