import React, { useState, useCallback } from 'react'
import ReactCardFlip from 'react-card-flip'
import { FaRegCheckCircle } from 'react-icons/fa'
import { FiArrowRightCircle } from 'react-icons/fi'

const shouldDisplayDiscount = (activeButton, index) => {
    return (activeButton === 1 || activeButton === 2 || activeButton === 3) && (index === 2 || index === 3 || index === 4)
}

const SubscribeButton = React.memo(({ item, isActive, onClick }) => {
    return (
        <button
            onClick={onClick}
            className={`${
                isActive ? 'bg-grad-button text-white border-transparent duration-300 transition-all' : 'text-gray-500 duration-300 transition-all'
            } font-medium duration-300 active:scale-95 transition-all hover:bg-gradient-to-r from-[#ed8ce3] to-[#6f96dd] hover:text-white py-2 ss:min-w-[200px] min-w-[140px] md:px-4 max-w-[230px] md:w-full rounded-full border-[1px] border-gray-500`}
        >
            {item.title}
        </button>
    )
})

const discounts = {
    1: '- 10%',
    2: '- 15%',
    3: '- 25%'
}

const SubscribeSection = () => {
    const [activeButton, setActiveButton] = useState(0)
    const cards = [
        {
            title: 'Стартовый +',
            content: 'Селлерам с выручкой до 250 000 ₽/мес',
            cost: '690 ₽',
            details: 'Подробная информация о тарифе Стартовый +.',
            more: ['70% функций сервиса', '2 магазина', '1 сотрудник (Только вы)']
        },
        {
            title: 'Стартовый Max',
            content: 'Селлерам с выручкой до 500 000 ₽/мес',
            cost: '2 990 ₽',
            details: 'Подробная информация о тарифе Стартовый Max.',
            more: ['90% функций сервиса', '2 магазина', '2 сотрудника (Вы + 1 сотрудник)']
        },
        {
            title: 'Профи',
            content: 'Без ограничения по выручке',
            cost: '6 990 ₽',
            cost3: '6 291 ₽',
            cost6: '13 491 ₽',
            cost12: '15 491 ₽',
            details: 'Подробная информация о тарифе Профи.',
            more: ['100% функций сервиса', 'Экспорт отчетов в Excel', '4 магазина', '3 сотрудник (Вы + 2 сотрудника)']
        },
        {
            title: 'Корпоративный',
            content: 'Без ограничения по выручке',
            cost: '14 990 ₽',
            cost3: '16 999 ₽',
            cost6: '17 999 ₽',
            cost12: '18 999 ₽',
            details: 'Подробная информация о тарифе Корпоративный.',
            more: ['100% функций сервиса', 'Экспорт отчетов в Google Sheets', '12 магазинов', '5 сотрудников (Вы + 4 сотрудника)', 'Интеграция МойСклад', 'Приоритетная поддержка']
        },
        {
            title: 'Корпоративный Max',
            content: 'Без ограничения по выручке',
            cost: '19 990 ₽',
            cost3: '20 999 ₽',
            cost6: '22 999 ₽',
            cost12: '23 999 ₽',
            details: 'Подробная информация о тарифе Корпоративный Max.',
            more: ['100% функций сервиса', 'Экспорт отчетов в Google Sheets', '12 магазинов', '5 сотрудников (Вы + 4 сотрудника)', 'Интеграция МойСклад', 'Приоритетная поддержка']
        }
    ]
    const [flippedCards, setFlippedCards] = useState(Array(cards.length).fill(false))

    const month = [
        { id: 0, title: '1 месяц' },
        { id: 1, title: '3 месяца' },
        { id: 2, title: '6 месяцев' },
        { id: 3, title: '12 месяцев' }
    ]

    const handleClick = useCallback((id) => {
        setActiveButton(id)
    }, [])

    const handleFlip = (index) => {
        setFlippedCards((prev) => {
            const newFlippedCards = [...prev]
            newFlippedCards[index] = !newFlippedCards[index]
            return newFlippedCards
        })
    }

    return (
        <section className='mt-10 md:mt-20' id='cost'>
            <h2 className='text-black font-bold ss:text-3xl text-2xl md:text-5xl xl:text-[60px]'>Выберите тариф</h2>
            <div className='mt-10 flex flex-col gap-10'>
                <div className='flex gap-5 flex-wrap md:overflow-hidden'>
                    {month.map((item) => (
                        <SubscribeButton key={item.id} item={item} isActive={activeButton === item.id} onClick={() => handleClick(item.id)} />
                    ))}
                </div>
                <div className='flex flex-wrap gap-3 ss:gap-5 justify-center xl:justify-start'>
                    {cards.map(
                        (item, index) =>
                            (activeButton === 0 || index >= 2) && (
                                <ReactCardFlip key={index} isFlipped={flippedCards[index]} flipDirection='horizontal'>
                                    <div
                                        key={index}
                                        className={`p-10 flex flex-col h-[420px] lg:max-w-[340px] lg:min-w-[340px] ss:min-w-[452px] md:min-w-[470px] relative gap-5 sm:max-w-[320px] sm:min-w-[320px] ss:max-w-[600px] md:max-w-[480px] max-w-[300px] w-full ${
                                            index === 4 && activeButton === 3 ? 'bg-[#e5fff9]' : 'bg-[#eaf3fd]'
                                        }  rounded-xl hover:bg-white shadowCard duration-300 transition-all`}
                                    >
                                        {shouldDisplayDiscount(activeButton, index) && (
                                            <div className='bg-[#2a84ee] absolute right-0 top-0 rounded-se-lg text-white font-medium text-xl p-3 rounded-bl-lg'>{discounts[activeButton]}</div>
                                        )}
                                        <div className='flex flex-col gap-3'>
                                            <h3 className='text-2xl font-semibold xl:text-2xl ss:text-3xl text-black'>{item.title}</h3>
                                            <p className='text-gray-500 ss:text-xl text-base'>{item.content}</p>
                                        </div>
                                        <div className='min-h-[68px] flex flex-col justify-end'>
                                            <p className='text-blue-600 text-3xl font-bold'>
                                                {activeButton === 1 ? item.cost3 : activeButton === 2 ? item.cost6 : activeButton === 3 ? item.cost12 : item.cost}
                                            </p>
                                        </div>
                                        <div className='flex flex-col gap-5'>
                                            <a href='https://app.bb-data.pro/signup' target='_blank'>
                                                <button
                                                    className={` font-medium duration-200 active:scale-95 transition-all bg-gradient-to-r from-[#ed8ce3] to-[#6f96dd] text-white py-3 text-xl px-4  w-full rounded-full `}
                                                >
                                                    Попробовать
                                                </button>
                                            </a>
                                            <button
                                                onClick={() => handleFlip(index)}
                                                className={`duration-300 flex gap-3 items-center justify-center active:scale-95 transition-all border-[1px] border-purple-500 text-black font-semibold py-3 text-xl px-4 w-full rounded-full `}
                                            >
                                                Подробнее
                                                <FiArrowRightCircle className='text-xl text-purple-500' />
                                            </button>
                                        </div>
                                    </div>
                                    <div className={`p-10 flex flex-col h-[520px] sm:h-[420px] lg:max-w-[340px] lg:min-w-[340px] md:min-w-[470px] ss:min-w-[452px] relative gap-5 sm:max-w-[320px] sm:min-w-[320px] ss:max-w-[600px] md:max-w-[480px] max-w-[300px] w-full ${
                                            index === 4 && activeButton === 3 ? 'bg-[#e5fff9]' : 'bg-[#eaf3fd]'
                                        }  rounded-xl hover:bg-white shadowCard duration-300 transition-all`}>
                                        <h3 className='text-xl font-semibold text-black'>Возможности</h3>
                                        <div className='flex flex-col gap-3'>
                                            {item.more.map((item, index) => (
                                                <div key={index} className={`${index !== 0 && 'border-t-[1px] border-gray-400'} flex pt-1 items-center gap-3`}>
                                                    <FaRegCheckCircle className='text-purple-600 text-base ' />
                                                    <p className='text-sm'>{item}</p>
                                                </div>
                                            ))}
                                        </div>
                                        <div className='h-full flex flex-col justify-end '>
                                            <button
                                                onClick={() => handleFlip(index)}
                                                className={`duration-300 flex gap-3 items-center justify-center active:scale-95 transition-all border-[1px] border-purple-500 text-black font-semibold py-3 text-xl px-4 w-full rounded-full `}
                                            >
                                                Назад
                                                <FiArrowRightCircle className='text-xl text-purple-500 rotate-180' />
                                            </button>
                                        </div>
                                    </div>
                                </ReactCardFlip>
                            )
                    )}
                </div>
            </div>
        </section>
    )
}

export default SubscribeSection
