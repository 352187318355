import React from 'react'
import DocsComponent from '../components/docs/Docs'

const Offerta = () => {
    return (
        <section className='flex justify-center'>
            <div className='max-w-[1920px] px-10 w-full'>
                <DocsComponent />
                <div className='font-bold text-[60px]'>Публичная оферта</div>
            </div>
        </section>
    )
}

export default Offerta
