import React from 'react'
import DocsComponent from '../components/docs/Docs'

const Cookies = () => {
    return (
        <section className='flex justify-center'>
            <div className='max-w-[1920px] px-10 w-full'>
                <DocsComponent />
                <h2 className='font-bold text-[60px]'>Использование cookie</h2>
                <p>
                    Продолжая использовать наш сайт, вы даете согласие на обработку файлов cookie, пользовательских данных (сведения о местоположении; тип и версия ОС; тип и версия Браузера; тип
                    устройства и разрешение его экрана; источник откуда пришел на сайт пользователь; с какого сайта или по какой рекламе; язык ОС и Браузера; какие страницы открывает и на какие кнопки
                    нажимает пользователь; ip-адрес) в целях функционирования сайта, проведения ретаргетинга и проведения статистических исследований и обзоров. В том числе с использованием сервиса
                    Google Analytics и Яндекс.Метрика. Если вы не хотите, чтобы ваши данные обрабатывались, измените настройки браузера или покиньте сайт.
                </p>
            </div>
        </section>
    )
}

export default Cookies
