import React from 'react'
import { FiArrowRightCircle } from 'react-icons/fi'
import Think from '../../assets/think.svg'
import Gift from '../../assets/gift.svg'

const SellerSection = () => {
    return (
        <div className='flex  justify-center'>
            <section className='flex bg-gradYellow relative max-w-[380px] sm:max-w-[728px] sm:w-full ss:max-w-[620px] md:max-w-full rounded-2xl md:p-10 justify-between p-5 pb-60 ss:pb-80 md:pb-10 mt-10 md:mt-20 items-center'>
                <div className='flex flex-col gap-2'>
                    <h2 className='text-black text-2xl ss:text-[30px] font-semibold'>
                        Начинающим <br className='hidden' />
                        селлерам с выручкой
                    </h2>
                    <div className='flex flex-col gap-5'>
                        <div>
                            <p className='text-xl md:text-2xl text-gray-500'>до 250 000 ₽/мес</p>
                            <span className='text-purple-600 font-bold text-2xl md:text-3xl'>Бесплатно</span>
                        </div>
                        <div className='flex flex-col gap-6'>
                            <a href='https://app.bb-data.pro/signup' target='_blank'>
                                <button
                                    className={` font-medium duration-200 active:scale-95 transition-all bg-gradient-to-r from-[#ed8ce3] to-[#6f96dd] text-white py-3 text-xl px-4  w-full rounded-full `}
                                >
                                    Попробовать
                                </button>
                            </a>
                            <button
                                className={`duration-300 flex gap-3 items-center justify-center active:scale-95 transition-all border-[1px] border-purple-500 text-black font-semibold py-3 text-xl px-4 w-full rounded-full `}
                            >
                                Подробнее
                                <FiArrowRightCircle className='text-xl text-purple-500' />
                            </button>
                        </div>
                    </div>
                </div>
                <img src={Gift} alt='icon' className='absolute max-w-[70px] xl:max-w-[180px] md:max-w-[120px] right-6 ss:right-16 ss:max-w-[140px] ss:bottom-40 bottom-20 -rotate-45 blur-[3px]' />
                <img
                    src={Gift}
                    alt='icon'
                    className='absolute max-w-[80px] xl:max-w-[150px] left-[45%] ss:max-w-[150px] md:max-w-[100px] ss:left-[5%] ss:bottom-20 md:left-[55%] bottom-40 md:top-20 rotate-[37deg]'
                />
                <img
                    src={Think}
                    alt='icon'
                    className='absolute max-w-[280px] md:max-w-[360px] xl:max-w-[450px] ss:left-[25%] ss:max-w-[360px] ss:bottom-[-96px] bottom-[-75px] left-3 md:left-[62%] xl:bottom-[-120px]'
                />
            </section>
        </div>
    )
}

export default SellerSection
