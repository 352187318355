import React, { useState } from 'react'
import Logo from '../../assets/logo1.png'
import { FiArrowRightCircle, FiMenu, FiX } from 'react-icons/fi'
import { Link } from 'react-router-dom'

const Header = () => {
    const [isOpen, setIsOpen] = useState(false)
    const links = ['О нас', 'Возможности', 'Тарифы']

    const toggleMenu = () => {
        setIsOpen(!isOpen)
    }

    return (
        <header className='flex items-center max-w-[1920px] w-full justify-between ss:px-10 xl:mb-20 p-4 bg-white md:shadow-none shadow-md'>
            <div className='flex items-center gap-4'>
                <Link to='/'>
                    <img src={Logo} alt='logo' className='max-w-[150px] md:max-w-[250px]' />
                </Link>
                <h2 className='hidden lg:block max-w-[300px] text-gray-500 text-sm'>
                    Аналитика и оцифровка продаж <span className='text-purple-600'>Wildberries</span> и <span className='text-blue-600'>Ozon</span>
                </h2>
            </div>

            <div className='hidden md:flex items-center justify-between md:justify-center md:gap-6 max-w-[350px] w-full'>
                {links.map((item, index) => (
                    <a
                        href={index === 0 ? '/#us' : index === 1 ? '/#about' : '/#cost'}
                        key={index}
                        className="relative active:scale-95 duration-300 xl:text-xl transition-all inline-block text-black after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-[1px] after:w-0 after:bg-black after:transition-all after:duration-200 hover:after:w-full"
                    >
                        {item}
                    </a>
                ))}
            </div>

            <div className='hidden ss:flex md:hidden items-center justify-between'>
                {links.map((item, index) => (
                    <a href='#' key={index} className='block text-black pt-1 px-4 sm:px-2 text-sm hover:bg-gray-100'>
                        {item}
                    </a>
                ))}
            </div>
            <div className='sm:flex items-center hidden sm:gap-3 gap-5 md:gap-3'>
                <a href='https://app.bb-data.pro/login' target='_blank'>
                    <button
                        className={`font-medium duration-200 sm:py-[6px] active:scale-95 transition-all bg-gradient-to-r from-[#ed8ce3] to-[#6f96dd] text-white py-2 text-sm px-3 md:py-2 md:text-xl md:px-4 rounded-full`}
                    >
                        Войти
                    </button>
                </a>
                <a href='https://app.bb-data.pro/signup' target='_blank'>
                    <button
                        className={`duration-300 flex gap-2 items-center  justify-center active:scale-95 transition-all border-[1px] border-purple-500 text-black font-semibold py-1 text-sm px-3 md:py-2 md:text-xl md:px-4 rounded-full`}
                    >
                        Зарегистрироваться
                        <FiArrowRightCircle className='text-lg md:text-xl text-purple-500' />
                    </button>
                </a>
            </div>
            <div className='sm:hidden flex items-center'>
                <button onClick={toggleMenu}>{isOpen ? <FiX className='text-xl' /> : <FiMenu className='text-xl' />}</button>
            </div>
            {isOpen && (
                <div className='ss:flex hidden flex-col ss:px-10 gap-2 p-2 absolute top-[64px] left-0 w-full bg-white shadow-lg z-10'>
                    <a href='https://app.bb-data.pro/login' target='_blank' className='flex justify-end'>
                        <button
                            className={`font-medium duration-200 active:scale-95 transition-all bg-gradient-to-r from-[#ed8ce3] to-[#6f96dd] text-white py-1 text-sm px-3 md:py-3 md:text-xl md:px-4 rounded-full`}
                        >
                            Войти
                        </button>
                    </a>
                    <a href='https://app.bb-data.pro/signup' target='_blank' className='flex justify-end'>
                        <button
                            className={`duration-300 flex gap-2 items-center justify-center active:scale-95 transition-all border-[1px] border-purple-500 text-black font-semibold py-1 text-sm px-3 md:py-2 md:text-xl md:px-4 rounded-full`}
                        >
                            Зарегистрироваться
                            <FiArrowRightCircle className='text-lg md:text-xl text-purple-500' />
                        </button>
                    </a>
                </div>
            )}

            {isOpen && (
                <nav className='absolute ss:hidden top-[64px] left-0 w-full bg-white shadow-lg z-10'>
                    {links.map((item, index) => (
                        <a
                            href='#'
                            key={index}
                            className='block text-black pt-1 px-4 text-sm hover:bg-gray-100'
                            onClick={toggleMenu} // Close menu on link click
                        >
                            {item}
                        </a>
                    ))}
                    <div className='flex flex-col gap-2 p-2'>
                        <a href='https://app.bb-data.pro/login' target='_blank'>
                            <button
                                className={`font-medium duration-200 active:scale-95 transition-all bg-gradient-to-r from-[#ed8ce3] to-[#6f96dd] text-white py-1 text-sm px-3 md:py-3 md:text-xl md:px-4 rounded-full`}
                            >
                                Войти
                            </button>
                        </a>
                        <a href='https://app.bb-data.pro/signup' target='_blank'>
                            <button
                                className={`duration-300 flex gap-2 items-center justify-center active:scale-95 transition-all border-[1px] border-purple-500 text-black font-semibold py-1 text-sm px-3 md:py-2 md:text-xl md:px-4 rounded-full`}
                            >
                                Зарегистрироваться
                                <FiArrowRightCircle className='text-lg md:text-xl text-purple-500' />
                            </button>
                        </a>
                    </div>
                </nav>
            )}
        </header>
    )
}

export default Header

// import React from 'react'
// import Logo from '../../assets/logo1.png'
// import { FiArrowRightCircle } from 'react-icons/fi'

// const Header = () => {
//     const links = ['О нас', 'Возможности', 'Тарифы']
//     return (
//         <header className='flex items-center justify-between xl:mb-20'>
//             <div className='flex flex-col items-center gap-4'>
//                 <img src={Logo} alt='logo' className='max-w-[50px]' />
//                 <h2 className='max-w-[300px] text-xs xl:text-base text-gray-500'>
//                     Аналитика и оцифровка продаж <span className='text-purple-600'>Wildberries</span> и <span className='text-blue-600'>Ozon</span>
//                 </h2>
//             </div>
//             <nav className='flex items-center justify-between max-w-[350px] w-full'>
//                 {links.map((item, index) => (
//                     <a
//                         href='#'
//                         key={index}
//                         className="relative active:scale-95 duration-300 transition-all inline-block text-black after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-[1px] after:w-0 after:bg-black after:transition-all after:duration-200 hover:after:w-full"
//                     >
//                         {item}
//                     </a>
//                 ))}
//             </nav>
//             <div className='flex items-center gap-5'>
//                 <a href='https://bb-data.pro/login' target='_blank'>
//                     <button className={` font-medium duration-200 active:scale-95 transition-all bg-gradient-to-r from-[#ed8ce3] to-[#6f96dd] text-white py-3 text-xl px-4  w-full rounded-full `}>
//                         Войти
//                     </button>
//                 </a>
//                 <a href='https://bb-data.pro/signup' target='_blank'>
//                     <button
//                         className={`duration-300 flex gap-3 items-center justify-center active:scale-95 transition-all border-[1px] border-purple-500 text-black font-semibold py-2 text-xl px-4 w-full rounded-full `}
//                     >
//                         Зарегестрироваться
//                         <FiArrowRightCircle className='text-xl text-purple-500' />
//                     </button>
//                 </a>
//             </div>
//         </header>
//     )
// }

// export default Header
