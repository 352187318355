import React from 'react'
import Header from '../components/header/Header'
import MainSection from '../components/mainSection/MainSection'
import TabsSection from '../components/tabsSection/TabsSection'
import TryitSection from '../components/tryitSection/TryitSection'
import AnalSection from '../components/analSection/AnalSection'
import WhySection from '../components/whySection/WhySection'
import transpert from '../assets/traspert.svg'
import time from '../assets/time.svg'
import SubscribeSection from '../components/subscribeSection/SubscribeSection'
import SellerSection from '../components/sellerSection/SellerSection'
import landing from '../assets/landing.svg'
import ReviewsSection from '../components/reviewsSection/ReviewsSection'
import Footer from '../components/footer/Footer'
import QuestSection from '../components/questSection/QuestSection'

const Main = () => {
    return (
        <>
            <div className='xl:flex items-center xl:flex-col xl:w-full'>
                <div className='xl:max-w-[1920px]'>
                    <div className='mx-2 md:mx-10 ss:mx-10 mt-10 md:mt-0 '>
                        <MainSection />
                        <TabsSection />
                        <TryitSection Image={transpert} image={landing} className={'md:absolute max-w-[280px] ss:max-w-[360px] mt-10 xl:mt-0  xl:max-w-[640px] right-14  bottom-[0px]'} />
                        <AnalSection />
                        <WhySection />
                        <TryitSection
                            Image={time}
                            className={'md:absolute max-w-[250px] md:bottom-[-40px] ss:max-w-[320px] md:max-w-[250px] xl:bottom-[-68px] xl:max-w-[440px] mt-10 right-20  bottom-[-68px]'}
                        />
                        <SubscribeSection />
                        <SellerSection />
                        {/* <ReviewsSection /> */}
                        <QuestSection />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Main
