import React from 'react'
import { FaRegCheckCircle } from 'react-icons/fa'
import Houses from '../../assets/houses.png'

const AnalSection = () => {
    const point = [
        'Сможете видеть прибыль по всем магазинам и маркетплейсам в одном месте',
        'Не потратите время на сбор данных из нескольких кабинетов или маркетплейсов',
        'Добавите любое количество магазинов'
    ]

    return (
        <section className='mt-10 md:mt-20' id='us'>
            <h2 className='text-black text-2xl ss:text-3xl xl:text-[60px] md:text-5xl xl:leading-[60px] font-bold'>
                Собираем единые отчёты по всем <br className='md:flex hidden' /> вашим магазинам
            </h2>
            <div className='flex justify- flex-col lg:flex-row gap-10 xl:gap-[300px] items-center w-full pt-5'>
                <div className='flex flex-col gap-4 md:w-full'>
                    <p className='text-base ss:text-2xl text-zinc-600'>C BB-DATA вы сможете:</p>
                    <div className='flex flex-col gap-3 ss:gap-5'>
                        {point.map((item, index) => (
                            <div key={index} className='flex bg-yellow gap-2 md:pt-8 ss:py-8 md:pb-8 rounded-xl p-3 ss:p-6'>
                                <FaRegCheckCircle className='text-purple-600 text-2xl mt-[6px]' />
                                <span className='text-base md:text-2xl font-normal lg:text-xl ss:text-xl md:max-w-[800px] w-full'>{item}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <img src={Houses} alt='icon' className='xl:max-w-[700px] ss:max-w-[600px] max-w-[320px]' />
            </div>
        </section>
    )
}

export default AnalSection
