import React from 'react'

const InstagramTip = () => {
    return (
        <section class='flex justify-center items-center'>
            <button
                href='/'
                class='group flex justify-center p-2 rounded-md drop-shadow-xl bg-insta from-gray-800 text-white font-semibold hover:translate-y-3 hover:rounded-[50%] transition-all duration-500 hover:from-[#331029] hover:to-[#310413]'
            >
                <svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='2em' height='2em' viewBox='0,0,256,256'>
                    <g
                        fill='#ffffff'
                        fill-rule='nonzero'
                        stroke='none'
                        stroke-width='1'
                        stroke-linecap='butt'
                        stroke-linejoin='miter'
                        stroke-miterlimit='10'
                        stroke-dasharray=''
                        stroke-dashoffset='0'
                        font-family='none'
                        font-weight='none'
                        font-size='none'
                        text-anchor='none'
                    >
                        <g transform='scale(8,8)'>
                            <path d='M11.46875,5c-3.55078,0 -6.46875,2.91406 -6.46875,6.46875v9.0625c0,3.55078 2.91406,6.46875 6.46875,6.46875h9.0625c3.55078,0 6.46875,-2.91406 6.46875,-6.46875v-9.0625c0,-3.55078 -2.91406,-6.46875 -6.46875,-6.46875zM11.46875,7h9.0625c2.47266,0 4.46875,1.99609 4.46875,4.46875v9.0625c0,2.47266 -1.99609,4.46875 -4.46875,4.46875h-9.0625c-2.47266,0 -4.46875,-1.99609 -4.46875,-4.46875v-9.0625c0,-2.47266 1.99609,-4.46875 4.46875,-4.46875zM21.90625,9.1875c-0.50391,0 -0.90625,0.40234 -0.90625,0.90625c0,0.50391 0.40234,0.90625 0.90625,0.90625c0.50391,0 0.90625,-0.40234 0.90625,-0.90625c0,-0.50391 -0.40234,-0.90625 -0.90625,-0.90625zM16,10c-3.30078,0 -6,2.69922 -6,6c0,3.30078 2.69922,6 6,6c3.30078,0 6,-2.69922 6,-6c0,-3.30078 -2.69922,-6 -6,-6zM16,12c2.22266,0 4,1.77734 4,4c0,2.22266 -1.77734,4 -4,4c-2.22266,0 -4,-1.77734 -4,-4c0,-2.22266 1.77734,-4 4,-4z'></path>
                        </g>
                    </g>
                </svg>
                <span class='absolute opacity-0 group-hover:opacity-100 group-hover:text-gray-700 group-hover:text-sm group-hover:-translate-y-10 duration-700'>Instagram</span>
            </button>
        </section>
    )
}

export default InstagramTip
