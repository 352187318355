import React, { useCallback, useState } from 'react'
import { GoPlus } from 'react-icons/go'
import Joy from '../../assets/joy.svg'

const SideBlock = ({ className, img, title, content, classNameIMG }) => {
    return (
        <section className={className}>
            <div className='flex flex-col gap-5 xl:gap-7'>
                <h2 className='text-2xl xl:text-[33px] ss:text-3xl font-bold text-white'>{title}</h2>
                <span className='text-white text-xl ss:text-2xl xl:text-[20px] font-normal'>{content}</span>
                <button className='w-full bg-white text-black rounded-3xl p-3 px-8 text-md md:text-lg font-semibold active:scale-95 duration-300 transition-all hover:opacity-90'>
                    Начать бесплатно
                </button>
            </div>
            <img src={img} alt='icon' className={classNameIMG} />
        </section>
    )
}
const QuestSection = () => {
    const [active, setActive] = useState(null)

    const handleClick = useCallback((id) => {
        setActive((prevActive) => (prevActive === id ? null : id))
    }, [])

    const quest = [
        {
            id: 0,
            title: 'Чем вы отличаетесь от Mpstat и других систем аналитики?',
            content:
                'Мы специализируемся на внутренней финансовой аналитике, поэтому данные у нас более точные и полезные. Другие сервисы аналитики обычно специализируются на внешнем анализе продаж всех поставщиков и берут данные из открытых источников. Мы же собираем данные только по вашим кабинетам, по тем доступам, которые вы нам предоставили.'
        },
        {
            id: 1,
            title: 'Сколько я могу пользоваться своими данными?',
            content: 'Вы можете пользоваться своими данными в любое время, пока у вас активен один из тарифов.'
        },
        {
            id: 2,
            title: 'Сколько времени занимает создание отчетов?',
            content: 'При первичной загрузке вашего магазина первые отчеты можно уже смотреть через 15-30 минут, после добавления ключа.'
        },
        {
            id: 3,
            title: 'В чем удобство вашей аналитики?',
            content:
                'Аналитика в нашем сервисе показывает достоверную информацию о Вашем бизнесе в цифрах с разных сторон: финансовые и товарные. На все время пользования у вас есть возможность использовать нашу поддержку как техническую, так и консультационную.'
        },
        {
            id: 4,
            title: 'Насколько точные данные получаются по API?',
            content:
                'У каждого маркетплейса есть свои показатели по погрешности. В целом при получении финансовых отчетов погрешность меньше одного процента, а оперативные данные (список заказов, отказов и т.д.) в среднем около 15%. Со своей стороны мы постарались минимизировать расхождения и учли многоэтапную перепроверку данных, чтоб вы смогли получить самые точные данные.'
        },
        {
            id: 5,
            title: 'Что делать если маркетплейс изменит API?',
            content:
                'Вам ничего делать не нужно. Вся забота по контролю работы и изменений лежит полностью на нас. Мы следим за всеми новостями от маркетплейсов, которые касаются их работы, а передовые системы мониторинга своевременно увидят и оповестят нас в случае сбоев.'
        },
        {
            id: 6,
            title: 'Безопасно ли передавать вам API ключи от кабинета?',
            content:
                'Абсолютно. Мы очень дорожим репутацией, поэтому серьезно относимся к безопасности данных. Ваши данные надежно хранятся в самых современных дата-центрах на территории РФ. Доступ к оборудованию имеет ограниченный круг лиц.'
        }
    ]

    return (
        <section className='mt-20 ss:mt-36'>
            <div className='flex flex-col gap-5 md:gap-10'>
                <div className='ss:flex gap-3 flex-col'>
                    <h2 className='text-black font-bold text-2xl md:text-5xl xl:text-[60px] xl:leading-[60px] ss:text-3xl'>Часто задаваемые вопросы</h2>
                    <p className='text-gray-500 text-base ss:text-xl md:text-2xl'>Собрали ответы на часто задаваемые вопросы от вас</p>
                </div>
                <div className='flex flex-col md:flex-row justify-center gap-10'>
                    <div className='flex flex-col gap-3 md:gap-6'>
                        {quest.map((item) => (
                            <div key={item.id} onClick={() => handleClick(item.id)} className='bg-[#eaf3fd] xl:px-10 ss:p-7 xl:py-8 px-3 py-4 rounded-xl w-full cursor-pointer group'>
                                <div className='flex justify-between items-center'>
                                    <h3 className='text-lg md:text-[26px] xl:text-[30px] ss:text-xl font-bold text-black'>{item.title}</h3>
                                    <span
                                        className={`border-2 rounded-full border-[#86beff] leading-[2px] flex items-center justify-center ss:w-8 ss:h-8 w-6 h-6 text-[#86beff] transition-all duration-500 ease-in-out ${
                                            active === item.id ? 'rotate-45' : ''
                                        } group-hover:border-purple-500 group-hover:text-purple-500`}
                                    >
                                        <GoPlus className='text-2xl ss:text-3xl' />
                                    </span>
                                </div>
                                <div className={`overflow-hidden transition-all duration-500 ease-in-out ${active === item.id ? 'max-h-[500px] opacity-100 mt-4' : 'max-h-0 opacity-0'}`}>
                                    <p className='text-gray-500 text-base ss:text-lg md:text-xl'>{item.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <SideBlock
                        className={`bg-grad p-5 pb-0 h-fit items-center  flex gap-10 flex-col rounded-2xl`}
                        img={Joy}
                        classNameIMG={'max-w-[250px] ss:max-w-[350px]'}
                        title={'Повысьте эффективность маркетинга и продаж с BB-DATA'}
                        content={'5-дневный пробный период с неограниченным доступом ко всем возможностям'}
                    />
                </div>
            </div>
        </section>
    )
}

export default QuestSection
