import React from 'react'
import { GiMultipleTargets } from 'react-icons/gi'
import { LiaLaptopCodeSolid } from 'react-icons/lia'
import { PiBookOpenTextLight } from 'react-icons/pi'
import { FaUsers } from 'react-icons/fa'
import { RiSecurePaymentLine } from 'react-icons/ri'
import { MdLiveHelp } from 'react-icons/md'

const WhySection = () => {
    const inf = [
        {
            title: 'Точность данных',
            content: 'Данные в отчётах сервиса полностью совпадает с данными ваших магазинов на маркетплейсах.',
            icon: <GiMultipleTargets />
        },
        {
            title: 'Большой функционал',
            content: 'Полный набор инструментов для внутренней аналитики для Wildberries и Ozon. Более 10 полезных отчётов c удобными настраиваемыми фильтрами и параметрами.',
            icon: <LiaLaptopCodeSolid />
        },
        {
            title: 'Легкость освоения',
            content: 'Наши отчёты просты и удобны. Все отчёты имеют подробные инструкции и видео, помогающие легко читать их и использовать.',
            icon: <PiBookOpenTextLight />
        },
        {
            title: 'Подходит всем',
            content:
                'BB-Data создан селлерами для селлеров. Наш опыт в товарном бизнесе и разработке IT систем позволил создать полезный и удобный продукт, поэтому он подойдёт как для новичком. так и для опытных селлеров.',
            icon: <FaUsers />
        },
        {
            title: 'Конфиденциальность',
            content: 'Ваша конфиденциальность для нас самый главный приоритет. Все ваши данные надёжно сохранены, а информация об этом записана в договоре-оферте.',
            icon: <RiSecurePaymentLine />
        },
        {
            title: 'Служба заботы',
            content: 'Наша служба заботы готова решить практически любые вопросы и в режиме 24/7',
            icon: <MdLiveHelp />
        }
    ]
    return (
        <section className='mt-10 md:mt-20'>
            <h2 className='text-black font-bold text-2xl ss:text-3xl md:text-5xl xl:text-[60px]'>Почему выбирают BB-DATA</h2>
            <div className='flex flex-wrap gap-8 justify-center items-center mt-5 md:mt-10'>
                {inf.map((item, index) => (
                    <div key={index} className='rounded-2xl bg-[#eaf3fd] md:h-[320px] xl:h-[277px] flex flex-col gap-3 w-full md:w-[450px] lg:w-[506px] p-5 ss:p-10'>
                        <div className='flex items-center gap-6'>
                            <span className='text-3xl ss:text-[50px] text-purple-500'>{item.icon}</span>
                            <h3 className='text-xl ss:text-[30px] text-black font-semibold'>{item.title}</h3>
                        </div>
                        <p className='text-base ss:text-xl text-[#707070] font-medium'>{item.content}</p>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default WhySection
