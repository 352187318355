import React from 'react'
import Boxes from '../../assets/boxes.png'

const MainSection = () => {
    return (
        <section className='flex flex-col md:flex-row md:mt-10 items-center justify-between w-full'>
            <div className='max-w-[975px] flex flex-col md:gap-10 gap-5'>
                <h2 className='text-xl ss:text-3xl ss:leading-8 md:text-5xl md:leading-[48px] xl:text-[60px] sm:text-[40px] sm:leading-10 font-semibold  xl:text-left xl:leading-[60px]'>
                    <span className='text-sky-600'>Увеличьте прибыль</span> на <br className='hidden md:flex' /> маркетплейсах с помощью <br className='hidden ss:flex md:flex sm:hidden' /> контроля
                    всех цифр в одном <br className='md:hidden' /> месте
                </h2>
                <p className='text-gray-500 text-base xl:text-[30px] sm:text-[26px] ss:text-xl max-w-[850px] font-medium xl:leading-8 xl:text-left'>
                    <span className='text-gray-500'>BB-DATA</span> - онлайн-сервис внутренней аналитики и финансового учета для маркетплейсов Wildberries и Ozon
                </p>
                <div className='bg-grad p-6 ss:p-10 max-w-[700px] pb-10 flex flex-col gap-5 rounded-3xl mx-auto md:mx-0'>
                    <p className='text-white text-lg ss:text-2xl'>
                        Начните свой <span className='font-semibold'>5-дневный пробный период</span> с неограниченным доступом ко всем возможностям
                    </p>
                    <div className='flex flex-col items-center gap-3'>
                        <a href='https://app.bb-data.pro/signup' target='_blank' className='w-full'>
                            <button className='w-full bg-white text-black rounded-3xl p-3 px-8 text-md xl:text-lg font-semibold active:scale-95 duration-300 transition-all hover:opacity-90'>
                                Попробовать бесплатно
                            </button>
                        </a>
                        <p className='text-white ss:text-xl'>или</p>
                        <a href='https://app.bb-data.pro/signup' target='_blank' className='w-full'>
                            <button className='w-full bg-white text-black rounded-3xl p-3 text-md xl:text-lg font-semibold active:scale-95 duration-300 transition-all hover:opacity-90'>
                                Записаться на демонстрацию
                            </button>
                        </a>
                    </div>
                </div>
            </div>
            <div className='hidden lg:block'>
                <img src={Boxes} alt='img' className='lg:max-w-[500px] xl:max-w-[800px]' />
            </div>
        </section>
    )
}

export default MainSection
